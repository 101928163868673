import { Input } from 'components/Form';

import { StyleWrapper, StyleTitle, TagInfo, InfoCard } from './styles';
import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { HeaderTitle, LinkToSimulation } from 'views/Profile/styles';

export const StyleForm = ({
  profile = {},
  handleChangeStyleSimulator,
  colorPrimary,
  bgColorPrimary,
  bgColorSecondary,
  tagManager,
  handleChange,
  urlSimulator,
  handleSubmitStyleSimulator = () => {},
}) => {
  if (!profile.isPromoter || profile.isPromoter <= 0) {
    return <></>;
  }

  return (
    <Card>
      <CardHeader>
        <HeaderTitle>Personalizar Simulador</HeaderTitle>
        <LinkToSimulation
          href={urlSimulator}
          target="_blank"
          className="btn btn-primary"
        >
          <i className="fas fa-external-link-alt" /> Ver simulador
        </LinkToSimulation>
      </CardHeader>
      <CardBody>
        <div className="alert alert-primary">
          <i className="fas fa-question-circle" /> Personalize seu simulador
          alterando a logo e as cores.
        </div>
        <div className="row mt-4">
          <div className="col-12 col-lg-6">
            <StyleWrapper>
              <StyleTitle>Imagens</StyleTitle>
              <Input
                label={
                  <>
                    Logo{' '}
                    <small className="badge badge-primary">
                      Tamanho recomendado: 150x40 pixels
                    </small>
                  </>
                }
                placeholder="Logo"
                name="logo"
                type="file"
                onChange={handleChangeStyleSimulator}
              />
            </StyleWrapper>
            <StyleWrapper>
              <StyleTitle>Cores</StyleTitle>
              <Input
                label="Cor Botão"
                placeholder="Cor Botão"
                name="colorPrimary"
                type="color"
                value={colorPrimary}
                onChange={handleChangeStyleSimulator}
                onBlur={handleChangeStyleSimulator}
              />
              <Input
                label="Cor Primária"
                placeholder="Cor Primária"
                name="bgColorPrimary"
                type="color"
                value={bgColorPrimary}
                onChange={handleChangeStyleSimulator}
                onBlur={handleChangeStyleSimulator}
              />

              <Input
                label="Cor Secundária"
                placeholder="Cor Secundária"
                name="bgColorSecondary"
                type="color"
                value={bgColorSecondary}
                onChange={handleChangeStyleSimulator}
                onBlur={handleChangeStyleSimulator}
              />
            </StyleWrapper>
          </div>
          <div className="col-12 col-lg-6">
            {/* <StyleTitle>Pixel Facebook</StyleTitle>
        <Input
          label="CODIGO PIXEL"
          placeholder="Ex: 5585556669998"
          name="pixel"
          value={tagManager}
          onChange={handleChange}
          onBlur={handleChange}
        />
        <Input
          label="CODIGO CONFIRMAÇÃO DOMINIO"
          placeholder="Ex: 2sdf2e5q2a33q6er6r5522d"
          name="tagManager"
          value={tagManager}
          onChange={handleChange}
          onBlur={handleChange}
        /> */}
            <StyleTitle>Tag Manager</StyleTitle>
            <Input
              label="ID TAG"
              placeholder="Ex: GTM-DDDDDDD"
              name="tagManager"
              value={tagManager}
              onChange={handleChange}
              onBlur={handleChange}
            />
            <TagInfo className="text-danger">
              <strong className="font-weight-bold">ATENÇÃO:</strong> Obtenha o
              ID do TAG MANAGER para conseguir utilizá-lo.
            </TagInfo>
            <a
              href="https://tagmanager.google.com"
              target="_blank"
              className="btn btn-info btn-sm mt-1 mt-md-0"
            >
              <i className="fas fa-external-link-alt" /> Clique aqui para
              resgatar sua TAG
            </a>
            <InfoCard>
              <StyleTitle>Principais Tags [Click ID]</StyleTitle>
              <p className="mt-1 mb-1">
                <strong>STEP 1:</strong>{' '}
                <span className="badge badge-info text-lowercase">
                  btn-simulacao
                </span>{' '}
                | <strong>Ok, Quero simular</strong>
              </p>
              <p className="mt-1 mb-1">
                <strong>STEP 2:</strong>{' '}
                <span className="badge badge-info text-lowercase">
                  btn-confirmacao
                </span>{' '}
                | <strong>Quero contratar</strong>
              </p>
              <p className="mt-1 mb-1">
                <strong>STEP 3:</strong>{' '}
                <span className="badge badge-info text-lowercase">
                  btn-dados-pessoais
                </span>{' '}
                | <strong>Ok, Confirmar dados pessoais</strong>
              </p>
              <p className="mt-1 mb-1">
                <strong>STEP 4:</strong>{' '}
                <span className="badge badge-info text-lowercase">
                  btn-endereco
                </span>{' '}
                | <strong>Ok, Confirmar endereço</strong>
              </p>
              <p className="mt-1 mb-1">
                <strong>STEP 5:</strong>{' '}
                <span className="badge badge-info text-lowercase">
                  btn-finalizar
                </span>{' '}
                | <strong>Finalizar</strong>
              </p>
            </InfoCard>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <Button
          onClick={handleSubmitStyleSimulator}
          className="btn btn-success"
        >
          Salvar alterações
        </Button>
      </CardFooter>
    </Card>
  );
};
