import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row
} from 'reactstrap';

const ImageHeaderComponenet = ({ landingPage, setLandingPage = () => {} }) => {
  return (
    <>
      <Card className="shadow mb-4">
        <CardHeader>
          <h2>Imagem destaque</h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="3">
              <Label>
                <div className="border rounded bg-white">
                  <img
                    className="w-100"
                    width={300}
                    alt="peoplesmartphone.png"
                    src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone.png"
                  />
                  <Alert color="secondary" className="m-0 text-center">
                    <Input
                      type="radio"
                      name="img-girl-lp"
                      value="peoplesmartphone.png"
                      onChange={(ev) => {
                        setLandingPage({
                          ...landingPage,
                          image: ev.target.value
                        });
                      }}
                      checked={
                        landingPage.image === 'peoplesmartphone.png'
                          ? true
                          : false
                      }
                    />
                    Selecionar
                  </Alert>
                </div>
              </Label>
            </Col>
            <Col md="3">
              <Label>
                <div className="border rounded bg-white">
                  <img
                    className="w-100"
                    width={300}
                    alt="peoplesmartphone.png"
                    src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone2.png"
                  />
                  <Alert color="secondary" className="m-0 text-center">
                    <Input
                      type="radio"
                      name="img-girl-lp"
                      value="peoplesmartphone2.png"
                      onChange={(ev) => {
                        setLandingPage({
                          ...landingPage,
                          image: ev.target.value
                        });
                      }}
                      checked={
                        landingPage.image === 'peoplesmartphone2.png'
                          ? true
                          : false
                      }
                    />
                    Selecionar
                  </Alert>
                </div>
              </Label>
            </Col>
            <Col md="3">
              <Label>
                <div className="border rounded bg-white">
                  <img
                    className="w-100"
                    width={300}
                    alt="peoplesmartphone.png"
                    src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone3.png"
                  />
                  <Alert color="secondary" className="m-0 text-center">
                    <Input
                      type="radio"
                      name="img-girl-lp"
                      value="peoplesmartphone3.png"
                      onChange={(ev) => {
                        setLandingPage({
                          ...landingPage,
                          image: ev.target.value
                        });
                      }}
                      checked={
                        landingPage.image === 'peoplesmartphone3.png'
                          ? true
                          : false
                      }
                    />
                    Selecionar
                  </Alert>
                </div>
              </Label>
            </Col>
            <Col md="3">
              <Label>
                <div className="border rounded bg-white">
                  <img
                    className="w-100"
                    width={300}
                    alt="peoplesmartphone.png"
                    src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone4.png"
                  />
                  <Alert color="secondary" className="m-0 text-center">
                    <Input
                      type="radio"
                      name="img-girl-lp"
                      value="peoplesmartphone4.png"
                      onChange={(ev) => {
                        setLandingPage({
                          ...landingPage,
                          image: ev.target.value
                        });
                      }}
                      checked={
                        landingPage.image === 'peoplesmartphone4.png'
                          ? true
                          : false
                      }
                    />
                    Selecionar
                  </Alert>
                </div>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Label>
                <div className="border rounded bg-white">
                  <img
                    className="w-100"
                    width={300}
                    alt="peoplesmartphone.png"
                    src="https://izidev.temfgts.com.br/v1/image/peopleoldwoman1.png"
                  />
                  <Alert color="secondary" className="m-0 text-center">
                    <Input
                      type="radio"
                      name="img-girl-lp"
                      value="peopleoldwoman1.png"
                      onChange={(ev) => {
                        setLandingPage({
                          ...landingPage,
                          image: ev.target.value
                        });
                      }}
                      checked={
                        landingPage.image === 'peopleoldwoman1.png'
                          ? true
                          : false
                      }
                    />
                    Selecionar
                  </Alert>
                </div>
              </Label>
            </Col>
            <Col md="3">
              <Label>
                <div className="border rounded bg-white">
                  <img
                    className="w-100"
                    width={300}
                    alt="peoplesmartphone.png"
                    src="https://izidev.temfgts.com.br/v1/image/peopleoldwoman2.png"
                  />
                  <Alert color="secondary" className="m-0 text-center">
                    <Input
                      type="radio"
                      name="img-girl-lp"
                      value="peopleoldwoman2.png"
                      onChange={(ev) => {
                        setLandingPage({
                          ...landingPage,
                          image: ev.target.value
                        });
                      }}
                      checked={
                        landingPage.image === 'peopleoldwoman2.png'
                          ? true
                          : false
                      }
                    />
                    Selecionar
                  </Alert>
                </div>
              </Label>
            </Col>
            <Col md="3">
              <Label>
                <div className="border rounded bg-white">
                  <img
                    className="w-100"
                    width={300}
                    alt="peoplesmartphone.png"
                    src="https://izidev.temfgts.com.br/v1/image/peopleoldman1.png"
                  />
                  <Alert color="secondary" className="m-0 text-center">
                    <Input
                      type="radio"
                      name="img-girl-lp"
                      value="peopleoldman1.png"
                      onChange={(ev) => {
                        setLandingPage({
                          ...landingPage,
                          image: ev.target.value
                        });
                      }}
                      checked={
                        landingPage.image === 'peopleoldman1.png' ? true : false
                      }
                    />
                    Selecionar
                  </Alert>
                </div>
              </Label>
            </Col>
            <Col md="3">
              <Label>
                <div className="border rounded bg-white">
                  <img
                    className="w-100"
                    width={300}
                    alt="peoplesmartphone.png"
                    src="https://izidev.temfgts.com.br/v1/image/peopleoldman2.png"
                  />
                  <Alert color="secondary" className="m-0 text-center">
                    <Input
                      type="radio"
                      name="img-girl-lp"
                      value="peopleoldman2.png"
                      onChange={(ev) => {
                        setLandingPage({
                          ...landingPage,
                          image: ev.target.value
                        });
                      }}
                      checked={
                        landingPage.image === 'peopleoldman2.png' ? true : false
                      }
                    />
                    Selecionar
                  </Alert>
                </div>
              </Label>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default ImageHeaderComponenet;
