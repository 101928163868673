import QrCodeComponent from 'components/Form/QrCodeComponent';
import Header from 'components/Headers/Header';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { updateLandingPage } from 'services/User/LandingPage';
import { getLandingPage } from 'services/User/LandingPage';
import Swal from 'sweetalert2';
import { phoneMask } from 'utils/mask';
import CommonQuestionsComponentView from './Components/CommonQuestionsComponentView';
import ReactQuill from 'react-quill';
import { BodyTitle } from 'views/Profile/styles';
import { TextStyled } from 'views/Profile/styles';

const SimulationMultiBanksView = () => {
  const [form, setForm] = useState({
    pixel: null,
    tagManager: null,
    contactPhone: null,
    url: null,
    infoPage: {
      useQuestions: true,
      usePolicy: 0,
      policy: '',
    },
  });

  const [questions, setQuestions] = useState([
    {
      title: '',
      text: '',
    },
  ]);

  const [tabConfiguration, setTabConfiguration] = useState(1);

  const handleGetLandingPage = () => {
    Swal.close();
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getLandingPage('simulator-multibanks').then((res) => {
      Swal.close();
      const result = res.data.resultSet;
      setForm({
        pixel: result.pixel,
        tagManager: result.tagManager,
        contactPhone: phoneMask(result.contactPhone),
        url: result.url,
        infoPage: result.infoPage,
      });

      setQuestions(
        result?.infoPage?.questions ?? [
          {
            title: '',
            text: '',
          },
        ]
      );
    });
  };

  const handleSubmit = () => {
    Swal.fire({
      title: 'Salvando alteração',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const dataSend = {
      ...form,
      infoPage: {
        ...form.infoPage,
        questions,
      },
    };
    updateLandingPage('simulator-multibanks', dataSend)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Registro alterado com sucesso!',
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleGetLandingPage();
  }, []);

  return (
    <>
      <Header showCards={false} />
      <section className="mt--8 container-fluid">
        <Card className="shadow mb-4">
          <CardHeader>
            <Row>
              <Col>
                <h2 className="mt-2">
                  <i className="fas fa-calculator" /> Simulador MultiBancos -
                  Personalizar
                </h2>
              </Col>
              <Col className="text-right">
                <a
                  href={form.url}
                  target="_blank"
                  className="btn btn-primary"
                  size="sm"
                >
                  <i className="fas fa-external-link-alt" />
                  {` `}
                  Ver Simulador MultiBancos
                </a>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Alert color="warning">
              <h3 className="m-0 text-white">
                Atenção: A izidev não realiza configuração no píxel e
                tagmanager. Não nos responsábilizamos pelo trabalho realizado
                pela equipe de marketing ou terceiros.
              </h3>
            </Alert>
            <Row>
              <Col md="7">
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>ID Pixel</Label>
                      <Input
                        value={form.pixel}
                        onChange={(ev) => {
                          setForm({
                            ...form,
                            pixel: ev.target.value.replace(/[^0-9]/g, ''),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>ID TAG MANAGER</Label>
                      <Input
                        value={form.tagManager}
                        onChange={(ev) => {
                          setForm({
                            ...form,
                            tagManager: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Telefone Contato / Suporte</Label>
                      <Input
                        value={form.contactPhone}
                        onChange={(ev) => {
                          setForm({
                            ...form,
                            contactPhone: phoneMask(ev.target.value),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col>
                <QrCodeComponent url={form.url} />
              </Col>
            </Row>
            <Nav
              tabs
              className="d-flex flex-md-wrap flex-md-row flex-column mt-3"
            >
              <NavItem
                className="flex-grow-1 flex-sm-grow-0 text-center"
                style={{ minWidth: '33.3%' }}
              >
                <NavLink
                  className="p-1"
                  active={tabConfiguration === 1}
                  onClick={() => setTabConfiguration(1)}
                >
                  <TextStyled>
                    <i className="fas fa-paint-brush" /> Personalizar simulador
                  </TextStyled>
                </NavLink>
              </NavItem>
              <NavItem
                className="flex-grow-1 flex-sm-grow-0 text-center"
                style={{ minWidth: '33.3%' }}
              >
                <NavLink
                  className="p-1"
                  active={tabConfiguration === 2}
                  onClick={() => setTabConfiguration(2)}
                >
                  <TextStyled>
                    <i className="fas fa-paint-brush" /> Tela conclusão proposta
                  </TextStyled>
                </NavLink>
              </NavItem>
              <NavItem
                className="flex-grow-1 w-auto flex-sm-grow-0 text-center"
                style={{ minWidth: '33.3%' }}
              >
                <NavLink
                  className="p-1"
                  active={tabConfiguration === 3}
                  onClick={() => setTabConfiguration(3)}
                >
                  <TextStyled>
                    <i className="fas fa-question" /> Perguntas frequentes
                  </TextStyled>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tabConfiguration}>
              <TabPane tabId={1}>
                <Card className="shadow mt-3">
                  <CardBody>
                    <Card className="shadow">
                      <CardHeader>
                        <Button
                          className="float-right"
                          color="primary"
                          size="sm"
                          onClick={() => {
                            setForm({
                              ...form,
                              infoPage: {
                                ...form?.infoPage,
                                colors: {
                                  barLogo: '#ffffff',
                                  btnContact: '#198754',
                                  btnSubmit: '#ffc107',
                                  textBtnContact: '#ffffff',
                                  textBtnSubmit: '#000000',
                                },
                              },
                            });
                          }}
                        >
                          <i className="fas fa-redo-alt" /> Restaurar cores
                        </Button>
                        <h3>Personalização de cores</h3>
                      </CardHeader>
                      <CardBody>
                        <BodyTitle>Barra superior</BodyTitle>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <Label>Barra logo</Label>
                              <Input
                                type="color"
                                value={form?.infoPage?.colors?.barLogo ?? null}
                                onChange={(ev) => {
                                  let colors = form?.infoPage?.colors;
                                  setForm({
                                    ...form,
                                    infoPage: {
                                      ...form?.infoPage,
                                      colors: {
                                        ...colors,
                                        barLogo: ev.target.value,
                                      },
                                    },
                                  });
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <BodyTitle>Botão de Atendimento</BodyTitle>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <Label>Cor do Botão</Label>
                              <Input
                                type="color"
                                value={
                                  form?.infoPage?.colors?.btnContact ?? null
                                }
                                onChange={(ev) => {
                                  let colors = form?.infoPage?.colors;
                                  setForm({
                                    ...form,
                                    infoPage: {
                                      ...form?.infoPage,
                                      colors: {
                                        ...colors,
                                        btnContact: ev.target.value,
                                      },
                                    },
                                  });
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label>Cor do Texto</Label>
                              <Input
                                type="color"
                                value={
                                  form?.infoPage?.colors?.textBtnContact ?? null
                                }
                                onChange={(ev) => {
                                  let colors = form?.infoPage?.colors;
                                  setForm({
                                    ...form,
                                    infoPage: {
                                      ...form?.infoPage,
                                      colors: {
                                        ...colors,
                                        textBtnContact: ev.target.value,
                                      },
                                    },
                                  });
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <BodyTitle>Botão de contratação</BodyTitle>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <Label>Cor do Botão</Label>
                              <Input
                                type="color"
                                value={
                                  form?.infoPage?.colors?.btnSubmit ?? null
                                }
                                onChange={(ev) => {
                                  let colors = form?.infoPage?.colors;
                                  setForm({
                                    ...form,
                                    infoPage: {
                                      ...form?.infoPage,
                                      colors: {
                                        ...colors,
                                        btnSubmit: ev.target.value,
                                      },
                                    },
                                  });
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label>Cor do texto</Label>
                              <Input
                                type="color"
                                value={
                                  form?.infoPage?.colors?.textBtnSubmit ?? null
                                }
                                onChange={(ev) => {
                                  let colors = form?.infoPage?.colors;
                                  setForm({
                                    ...form,
                                    infoPage: {
                                      ...form?.infoPage,
                                      colors: {
                                        ...colors,
                                        textBtnSubmit: ev.target.value,
                                      },
                                    },
                                  });
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    <Card className="mt-3 shadow">
                      <CardHeader>
                        <h3>Texto destaque</h3>
                      </CardHeader>
                      <CardBody>
                        <ReactQuill
                          value={form?.infoPage?.textStage1 ?? ''}
                          style={{ height: 350 }}
                          className="mb-5"
                          modules={{
                            toolbar: [
                              [
                                {
                                  header: [1, 2, 3, false],
                                },
                              ],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote',
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' },
                              ],
                              [],
                              ['clean'],
                            ],
                          }}
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video',
                          ]}
                          onChange={(ev) => {
                            setForm({
                              ...form,
                              infoPage: {
                                ...form?.infoPage,
                                textStage1: ev ?? '',
                              },
                            });
                          }}
                        />
                      </CardBody>
                    </Card>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={2}>
                <Card className="shadow">
                  <CardHeader>
                    <h3 className="m-0">IFRAME</h3>
                  </CardHeader>
                  <CardBody>
                    <p>
                      O IFRAME será adicionado logo abaixo das informações da
                      proposta
                    </p>
                    <h3>Tags</h3>
                    <Alert color="secondary">
                      <p className="m-0">
                        [[NUMBER_PROPOSAL]] - Número da proposta
                      </p>
                      <p className="m-0">[[NAME]] - Nome do cliente</p>
                      <p className="m-0">[[VALUE]] - Valor liberado contrato</p>
                    </Alert>
                    <textarea
                      className="form-control"
                      value={form?.infoPage?.iframe}
                      style={{ height: '300px' }}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          infoPage: {
                            ...form?.infoPage,
                            iframe: e.target.value,
                          },
                        });
                      }}
                    />
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={3}>
                <CommonQuestionsComponentView
                  form={form}
                  setForm={setForm}
                  questions={questions}
                  setQuestions={setQuestions}
                />
              </TabPane>
            </TabContent>
          </CardBody>
          <CardFooter>
            <Button onClick={handleSubmit} color="warning">
              SALVAR ALTERAÇÃO
            </Button>
          </CardFooter>
        </Card>
      </section>
    </>
  );
};

export default SimulationMultiBanksView;
