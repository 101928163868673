import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import {
  getStyles,
  getPromoter,
  updateStyles,
} from 'services/Promoter/PromoterService';

import { StyleForm } from './component/StyleForm';
import { Header, Hello } from './styles';
import { UserAccountFormComponent } from './component/AccountForm/userAccountFormComponent';
import {
  showProfileService,
  updateProfileService,
} from 'services/Register/ProfileService';
import { AccountForm } from './component/AccountForm';
import { StyleUserComponent } from './component/StyleForm/styleUserComponent';

const Profile = () => {
  const [dataPromoter, setDataPromoter] = useState({});
  const [urlSimulator, setUrlSimulator] = useState('');
  const [imagePeople, setImagePeople] = useState('');
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [logo, setLogo] = useState({
    name: '',
    base64: '',
    url: '',
  });
  const [bgColorPrimary, setBgColorPrimary] = useState('');
  const [bgColorSecondary, setBgColorSecondary] = useState('');
  const [colorPrimary, setColorPrimary] = useState('');
  const [tagManager, setTagManager] = useState('');

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = '';
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve({
          base64: baseURL,
          file: file,
        });
      };
    });
  };

  const styles = async () => {
    await getStyles().then((res) => {
      const result = res.data;
      setBgColorPrimary(result.resultSet.bgColorPrimary);
      setBgColorSecondary(result.resultSet.bgColorSecondary);
      setColorPrimary(result.resultSet.colorPrimary);
      setTagManager(result.resultSet.tagManager);
      setLogo({
        name: '',
        base64: '',
        url: result.resultSet.logo,
      });
    });
  };

  const promoter = async () => {
    await getPromoter().then((res) => {
      setDataPromoter(res.data.resultSet);
      setUrlSimulator(res.data.resultSet?.url);
    });
  };

  const handleSubmit = async () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja realizar esta alteração?',
      showCancelButton: true,
      cancelButtonText: 'NÃO, SAIR',
      confirmButtonText: 'SIM, SALVAR',
    }).then((res) => {
      if (!res.isConfirmed) return;

      setIsLoading(true);

      updateProfileService(profile)
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });

      // Swal.fire({
      //   title: 'Alterando informações',
      //   text: 'Por favor aguarde',
      //   allowOutsideClick: false,
      //   allowEscapeKey: false,
      //   didOpen: () => {
      //     Swal.showLoading();
      //   },
      // });

      // updateSimple({
      //   contactEmail,
      //   contactMovelPhone,
      //   preferenceBank,
      //   nameSlug,
      //   notificationPhone,
      // })
      //   .then(() => {
      //     Swal.fire({
      //       title: 'Sucesso',
      //       text: 'Atualização realizada',
      //       icon: 'success',
      //     });
      //     promoter();
      //   })
      //   .catch(() => {
      //     Swal.fire({
      //       title: 'Ops, ocorreu um erro',
      //       text: 'Ops ocorreu um erro',
      //       icon: 'error',
      //     });
      //   });
    });
  };

  const handleChangeStyleSimulator = (event) => {
    if (event.target.name === 'logo' && event.target.value !== '') {
      const logo = event.target.files[0] || null;

      getBase64(logo).then((result) => {
        setLogo({
          name: event.target.files[0].name,
          base64: result.base64,
        });
      });
    }

    if (event.target.name === 'bgColorPrimary') {
      setBgColorPrimary(event.target.value);
    }

    if (event.target.name === 'bgColorSecondary') {
      setBgColorSecondary(event.target.value);
    }

    if (event.target.name === 'colorPrimary') {
      setColorPrimary(event.target.value);
    }

    if (event.target.name === 'img-girl-lp' && event.target.checked === true) {
      setImagePeople(event.target.value);
    }
  };

  const handleSubmitStyleSimulator = async () => {
    await updateStyles({
      logo,
      bgColorPrimary,
      bgColorSecondary,
      colorPrimary,
      tagManager,
      imagePeople,
    })
      .then(() => {
        Swal.fire({
          title: 'Sucesso',
          text: 'Registro alterado com sucesso',
          icon: 'success',
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleShowProfile = async () => {
    showProfileService().then(({ data }) => {
      setProfile(data.resultSet);
    });
  };

  useEffect(() => {
    handleShowProfile();
    promoter();
    styles();
  }, []);

  return (
    <>
      <Header className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
        <div className="mask bg-gradient-default opacity-8" />
        <div className="container-fluid d-flex align-items-center">
          <div className="row">
            <div className="col-12">
              <Hello>Olá, {dataPromoter.name}</Hello>
              <p className="text-white mt-0 mb-5">
                Abaixo, você pode visualizar e personalizar seu perfil de forma
                simples e fácil
              </p>
            </div>
          </div>
        </div>
      </Header>
      <div className="container-fluid mt--9">
        <AccountForm
          profile={profile}
          setProfile={setProfile}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />
        <UserAccountFormComponent
          profile={profile}
          setProfile={setProfile}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />

        <div className="row mt-4">
          <div className="col-12">
            <StyleForm
              profile={profile}
              colorPrimary={colorPrimary}
              bgColorPrimary={bgColorPrimary}
              bgColorSecondary={bgColorSecondary}
              urlSimulator={urlSimulator}
              tagManager={tagManager}
              handleChangeStyleSimulator={handleChangeStyleSimulator}
              handleSubmitStyleSimulator={handleSubmitStyleSimulator}
            />

            <StyleUserComponent profile={profile} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
