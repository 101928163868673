import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap';
import {
  saveStyleUserService,
  showStyleUserService,
} from 'services/Register/ProfileService';
import getBase64 from 'utils/input-base64';
import { BodyTitle, HeaderTitle } from 'views/Profile/styles';

export const StyleUserComponent = ({ profile = {} }) => {
  const [isLoadingShowStyle, setIsLoadingShowStyle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [styleUser, setStyleUser] = useState({});
  const [logo, setLogo] = useState({
    name: '',
    base64: '',
    url: '',
  });
  const [config, setConfig] = useState({});

  const handleShowStyleUser = () => {
    setIsLoadingShowStyle(true);
    showStyleUserService()
      .then(({ data }) => {
        setStyleUser(data?.resultSet);
        setConfig(data?.resultSet?.config ?? {});
      })
      .then(() => {
        setIsLoadingShowStyle(false);
      })
      .catch(() => {
        setIsLoadingShowStyle(false);
      });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    saveStyleUserService({
      logo: logo,
      config: config,
    })
      .then(() => {
        window.location.reload();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    console.log(profile.uuid, profile.isSeller);
    if (!profile?.isSeller || profile?.isSeller <= 0) {
      return;
    }
    handleShowStyleUser();
  }, [profile?.isSeller]);

  if (isLoadingShowStyle) {
    return (
      <Alert color="secondary">
        <i className="fas fa-spin fa-circle-notch" /> Carregando...
      </Alert>
    );
  }

  if (!profile?.isSeller || profile?.isSeller <= 0) {
    return <></>;
  }

  return (
    <Card>
      <CardHeader>
        <HeaderTitle>
          <i className="fas fa-link" /> Link Auto contratação
        </HeaderTitle>
      </CardHeader>
      <CardBody>
        <Card className="shadow">
          <CardHeader>
            <HeaderTitle>
              <i className="fas fa-image" /> Logo
            </HeaderTitle>
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-md-6">
                {styleUser?.logo && styleUser?.logo !== '' && (
                  <img alt="logo" src={styleUser?.logo} width={180} />
                )}
                <Input
                  className="form-control form-control-alternative"
                  type="file"
                  onChange={(e) => {
                    const logo = e.target.files[0] || null;
                    getBase64(logo).then((result) => {
                      setLogo({
                        name: e.target.files[0].name,
                        base64: result.base64,
                      });
                    });
                  }}
                />
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className="mt-3 shadow">
          <CardHeader>
            <HeaderTitle>
              <i className="fas fa-palette" /> Personalização de cores
            </HeaderTitle>
          </CardHeader>
          <CardBody>
            <div>
              <BodyTitle>Barra superior</BodyTitle>
              <div className="row">
                <div className="col-md-6">
                  <Label>Cor da barra superior</Label>
                  <Input
                    type="color"
                    value={config?.barLogo}
                    onChange={(e) => {
                      setConfig({
                        ...config,
                        barLogo: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <BodyTitle>Botão de Atendimento</BodyTitle>
              <div className="row">
                <div className="col-md-6">
                  <Label>Cor do botão</Label>
                  <Input
                    type="color"
                    value={config?.btnSubmit}
                    onChange={(e) => {
                      setConfig({
                        ...config,
                        btnSubmit: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <Label>Cor do texto</Label>
                  <Input
                    type="color"
                    value={config?.textBtnSubmit}
                    onChange={(e) => {
                      setConfig({
                        ...config,
                        textBtnSubmit: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <BodyTitle>Botão de Contratação</BodyTitle>
              <div className="row">
                <div className="col-md-6">
                  <Label>Cor do botão</Label>
                  <Input
                    type="color"
                    value={config?.btnContact}
                    onChange={(e) => {
                      setConfig({
                        ...config,
                        btnContact: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <Label>Cor do texto</Label>
                  <Input
                    type="color"
                    value={config?.textBtnContact}
                    onChange={(e) => {
                      setConfig({
                        ...config,
                        textBtnContact: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        <Card className="mt-3 shadow">
          <CardHeader>
            <HeaderTitle>
              <i className="fas fa-link" /> Meus links
            </HeaderTitle>
          </CardHeader>
          <CardBody>
            {styleUser?.url?.result?.length > 0 &&
              styleUser?.url?.result.map((urlSimulation) => (
                <div className="mb-3">
                  <Label>{urlSimulation.name}</Label>
                  <InputGroup>
                    <input
                      className="form-control form-control-alternative"
                      type="url"
                      value={urlSimulation.url}
                      readOnly
                    />
                    <InputGroupAddon addonType="append">
                      <a
                        href={urlSimulation.url}
                        target="_blank"
                        className="btn btn-primary"
                      >
                        <i className="fas fa-external-link-alt" /> Acessar
                      </a>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              ))}
          </CardBody>
        </Card>
      </CardBody>
      <CardFooter>
        <Button
          className="btn btn-success"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          <i className="fas fa-save" /> Salvar alterações{' '}
          {isLoading && <i className="fas fa-spin fa-circle-notch" />}
        </Button>
      </CardFooter>
    </Card>
  );
};
