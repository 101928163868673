import FormRegisterAddressComponent from 'components/Form/Register/FormRegisterAddressComponent';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { createProposalConsignedService } from 'services/Consigned/ConsignedService';
import { ShowClientProposalService } from 'services/Register/ClientService';
import Swal from 'sweetalert2';
import { phoneMask, moneyMask, cepMask, maskDate } from 'utils/mask';

import { BankSelectData } from 'views/AccessBank/service/bankSelectData';
import { proposalConsignedValidator } from './ConsignedValidator';

const SelectedSimulationView = ({
  simulationSelected,
  financialSelected,
  contract,
  setContract = () => {},
}) => {
  const history = useHistory();
  const [errorMessages, setErrorMessages] = useState([]);
  const [hasProposal, setHasProposal] = useState(false);
  const [proposalData, setProposalData] = useState({
    numberProposal: null,
    urlFormalize: null,
  });

  useEffect(() => {
    setHasProposal(false);
    setContract({
      ...contract,
      febrabanId: financialSelected,
      simulation: simulationSelected,
    });
  }, []);

  const handleGetClientProposal = async () => {
    Swal.fire({
      title: 'Buscando',
      text: 'Por favor, aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await ShowClientProposalService(contract.cpf.replace(/[^0-9]/g, '')).then(
      ({ data }) => {
        if (!data?.resultSet || !data?.resultSet?.name) {
          Swal.fire({
            icon: 'warning',
            title: 'Não identificado',
            text: 'Registro não identificado na base',
          });
          return;
        }

        Swal.close();

        setContract({
          ...contract,
          name: data.resultSet?.name ?? '',
          email: data.resultSet?.email ?? '',
          sex: data.resultSet?.sex ?? '',
          motherName: data.resultSet?.nameMother ?? '',
          cep: cepMask(data.resultSet?.CEP ?? ''),
          address: data.resultSet?.address ?? '',
          numberAddress: data.resultSet?.numberAddress ?? '',
          district: data.resultSet?.district ?? '',
          city: data.resultSet?.city ?? '',
          state: data.resultSet?.state ?? '',
          complement: data.resultSet?.email ?? '',
          documentRg: data.resultSet?.documentRG ?? '',
          dateEmissionRg: dayjs(data.resultSet?.dateEmissionRG ?? '').format(
            'DD/MM/YYYY'
          ),
          organEmissionRg: data.resultSet?.emissionRG ?? '',
          stateRg: data.resultSet?.ufRG ?? '',
        });
      }
    );
  };

  const handleOperation = async () => {
    const missingFields = proposalConsignedValidator(contract);

    if (missingFields.length > 0) {
      const messages = missingFields.map((field) => `- ${field}`).join('<br>');
      setErrorMessages(messages);
      return;
    }

    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja digitar o contrato?',
      confirmButtonText: 'Sim, confirmar',
      cancelButtonText: 'Não, sair',
      showCancelButton: true,
    }).then(async (confirm) => {
      if (!confirm.isConfirmed) return;
      Swal.fire({
        title: 'Salvando proposta',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const result = await createProposalConsignedService(contract);

      if (!result) {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Não foi possível digitar o contrato, verifique os dados e tente novamente',
        });
        return;
      }

      if (!result.status) {
        Swal.fire({
          icon: 'error',
          title: 'Atenção',
          text: result.resultSet.message,
        });
        return;
      }

      if (
        !result.resultSet?.numberProposal ||
        result.resultSet?.numberProposal === 'NT'
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Atenção',
          text: 'Não foi possível gerar a proposta, tente novamente!',
        });
        return;
      }

      Swal.close();

      setHasProposal(true);
      setProposalData({
        numberProposal: result.resultSet?.numberProposal,
        urlFormalize: null,
      });
    });
  };

  useEffect(() => {
    const missingFields = proposalConsignedValidator(contract);

    if (missingFields.length === 0) {
      setErrorMessages([]);
    }
  }, [contract]);

  return (
    <>
      {!hasProposal ? (
        <>
          <Card className="mt-4 shadow">
            <CardHeader>
              <h2>
                <i className="far fa-sticky-note" /> Detalhes da Proposta
              </h2>
            </CardHeader>
            <CardBody>
              <Card className="shadow mb-3">
                <CardHeader>Condição selecionada</CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <img
                        src={BankSelectData(financialSelected).img}
                        alt={financialSelected}
                        height="80px"
                      />
                      <h3 className="mt-2">
                        <span className="text-primary">TABELA:</span>{' '}
                        {simulationSelected.table}
                      </h3>
                    </Col>
                    <Col md="6">
                      <Row>
                        <Col md="6" className="d-flex align-items-stretch">
                          <Card className="bg-danger text-white w-100">
                            <CardBody>
                              <p className="m-0">Data primeira parcela</p>
                              <p className="m-0 font-weight-bold">
                                {simulationSelected.startDateExpire}
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col
                          md="6"
                          className="d-flex align-items-stretch mt-md-0 mt-2"
                        >
                          <Card className="bg-success text-white w-100">
                            <CardBody>
                              <p className="m-0">Valor Cliente</p>
                              <p className="m-0 font-weight-bold">
                                R${' '}
                                {moneyMask(
                                  Number(
                                    simulationSelected.valueClient
                                  ).toFixed(2)
                                )}
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="shadow">
                <CardHeader>
                  <i className="fas fa-user-check" /> Informações do cliente
                  <Button
                    color="primary"
                    size="sm"
                    className="float-right"
                    onClick={handleGetClientProposal}
                  >
                    <i className="fas fa-id-badge" /> Atualizar dados do cliente
                  </Button>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>
                          Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="name"
                          value={contract.name}
                          onChange={(ev) => {
                            setContract({ ...contract, name: ev.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>
                          CPF <span className="text-danger">*</span>
                        </Label>
                        <Input name="cpf" value={contract.cpf || ''} readOnly />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>
                          Data de Nascimento{' '}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          placeholder="--/--/----"
                          value={contract.birthDate || ''}
                          onChange={(ev) => {
                            setContract({
                              ...contract,
                              birthDate: maskDate(ev.target.value),
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>
                          Contato WhatsApp{' '}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          placeholder="(xx) x xxxx-xxxx"
                          value={contract.whatsappNumber || ''}
                          onChange={(ev) => {
                            setContract({
                              ...contract,
                              whatsappNumber: phoneMask(ev.target.value),
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>
                          RG <span className="text-danger">*</span>
                        </Label>
                        <Input
                          value={contract.documentRg}
                          onChange={(ev) => {
                            setContract({
                              ...contract,
                              documentRg: ev.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>
                          Data de Emissão <span className="text-danger">*</span>
                        </Label>
                        <Input
                          placeholder="--/--/----"
                          value={contract.dateEmissionRg}
                          onChange={(ev) => {
                            setContract({
                              ...contract,
                              dateEmissionRg: maskDate(ev.target.value),
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>
                          Orgão Emissor <span className="text-danger">*</span>
                        </Label>
                        <Input
                          value={contract.organEmissionRg}
                          onChange={(ev) => {
                            setContract({
                              ...contract,
                              organEmissionRg: ev.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>
                          Estado Emissor <span className="text-danger">*</span>
                        </Label>
                        <select
                          name="ufRG"
                          className="form-control"
                          value={contract.stateRg}
                          onChange={(ev) => {
                            setContract({
                              ...contract,
                              stateRg: ev.target.value,
                            });
                          }}
                        >
                          <option value="" disabled="">
                            Selecione...
                          </option>
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AP">Amapá</option>
                          <option value="AM">Amazonas</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="DF">Distrito Federal</option>
                          <option value="ES">Espírito Santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="MS">Mato Grosso do Sul</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PR">Paraná</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RN">Rio Grande do Norte</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="RO">Rondônia</option>
                          <option value="RR">Roraima</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="SP">São Paulo</option>
                          <option value="SE">Sergipe</option>
                          <option value="TO">Tocantins</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>
                          E-mail <span className="text-danger">*</span>
                        </Label>
                        <Input
                          value={contract.email}
                          onChange={(ev) => {
                            setContract({
                              ...contract,
                              email: ev.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>
                          Sexo <span className="text-danger">*</span>
                        </Label>
                        <select
                          name="sex"
                          className="form-control"
                          value={contract.sex}
                          onChange={(ev) => {
                            setContract({ ...contract, sex: ev.target.value });
                          }}
                        >
                          <option value="" disabled="">
                            Selecione...
                          </option>
                          <option value="M">Masculino</option>
                          <option value="F">Feminino</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>
                          Nome da Mãe <span className="text-danger">*</span>
                        </Label>
                        <Input
                          value={contract.motherName}
                          onChange={(ev) => {
                            setContract({
                              ...contract,
                              motherName: ev.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="shadow mt-4">
                <CardHeader>
                  <h3>
                    <i className="fas fa-map-marked-alt" /> Endereço
                  </h3>
                </CardHeader>
                <CardBody>
                  <FormRegisterAddressComponent
                    register={contract}
                    setRegister={setContract}
                    isRequired={true}
                  />
                </CardBody>
              </Card>

              <Card className="shadow mt-4">
                <CardHeader>
                  <i className="fas fa-university" /> Dados bancários
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label>
                          Banco <span className="text-danger">*</span>
                        </Label>
                        <select
                          className="form-control"
                          value={contract.bank}
                          onChange={(ev) => {
                            setContract({
                              ...contract,
                              bank: ev.target.value,
                            });
                          }}
                        >
                          <option value="">SELECIONE</option>
                          <option value="070">BRB - BANCO DE BRASÍLIA</option>
                          <option value="104">CAIXA ECONOMICA FEDERAL</option>
                          <option value="001">BANCO DO BRASIL S.A.</option>
                          <option value="237">BANCO BRADESCO</option>
                          <option value="341">BANCO ITAÚ S.A.</option>
                          <option value="336">BANCO C6 S.A.</option>
                          <option value="033">BANCO SANTANDER S.A.</option>
                          <option value="151">BANCO NOSSA CAIXA S.A.</option>
                          <option value="745">BANCO CITIBANK S.A.</option>
                          <option value="041">
                            BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.
                          </option>
                          <option value="756">BANCO SICOOB</option>
                          <option value="748">BANCO SICREDI</option>
                          <option value="085">
                            COOPERATIVA CENTRAL DE CRÉDITO - AILOS
                          </option>
                          <option value="399">HSBC BANK BRASIL S.A</option>
                          <option value="756">
                            BANCOOB - BANCO COOPERATIVO DO BRASIL S.A.
                          </option>
                          <option value="041">BANRISUL</option>
                          <option value="212">BANCO ORIGINAL</option>
                          <option value="260">NU BANK PAGAMENTOS S.A.</option>
                          <option value="318">BANCO BMG S.A.</option>
                          <option value="336">BANCO C6 S.A. (C6 BANK)</option>
                          <option value="077">BANCO INTER S.A.</option>
                          <option value="133">CRESOL</option>
                          {contract.febrabanId == '074' ? (
                            <>
                              <option value="077">INTERMEDIUM S.A.</option>
                              <option value="290">
                                PAGSEGURO INTERNET S.A (PAGBANK)
                              </option>
                              <option value="413">BANCO VOTORANTIM S.A</option>
                              <option value="197">STONE PAGAMENTOS S.A</option>
                              <option value="169">
                                BANCO OLÉ BONSUCESSO CONSIGNADO S.A
                              </option>
                              <option value="323">
                                MERCADO PAGO (CARTEIRA DIGITAL)
                              </option>
                              <option value="655">
                                NEON PAGAMENTOS S.A (FINTECH DO BANCO
                                VOTORANTIM)
                              </option>
                              <option value="637">BANCO SOFISA S.A</option>
                              <option value="336">
                                BANCO C6 S.A – C6 BANK
                              </option>
                            </>
                          ) : (
                            <></>
                          )}
                          <option value="074">BANCO SAFRA S.A</option>
                          <option value="623">BANCO PAN</option>
                          <option value="380">PICPAY SERVICOS S.A</option>
                          <option value="037">BANCO DO PARÁ</option>
                          <option value="121">AGIBANK</option>
                          <option value="335">BANCO DIGIO</option>
                          <option value="389">BANCO MERCANTIL</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup>
                        <Label>
                          Agência <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="agencyBank"
                          value={contract.agencyBank}
                          onChange={(ev) => {
                            setContract({
                              ...contract,
                              agencyBank: ev.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup>
                        <Label>
                          Conta <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="numberAccountBank"
                          value={contract.numberAccountBank}
                          onChange={(ev) => {
                            setContract({
                              ...contract,
                              numberAccountBank: ev.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup>
                        <Label>
                          Dígito Conta <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="digitAccount"
                          value={contract.digitAccountBank}
                          onChange={(ev) => {
                            setContract({
                              ...contract,
                              digitAccountBank: ev.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup>
                        <Label>
                          Tipo da conta <span className="text-danger">*</span>
                        </Label>
                        <select
                          name="typeAccount"
                          className="form-control"
                          value={contract.typeAccountBank}
                          onChange={(ev) => {
                            setContract({
                              ...contract,
                              typeAccountBank: ev.target.value,
                            });
                          }}
                        >
                          <option value="" disabled="">
                            Selecione...
                          </option>
                          <option value="01">Conta Corrente</option>
                          <option value="02">Conta Poupança</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardBody>
            <CardFooter>
              <Button color="success" onClick={handleOperation}>
                <i className="fas fa-clipboard-check" /> Digitar Proposta
              </Button>
            </CardFooter>
          </Card>

          {errorMessages.length > 0 && (
            <Alert color="warning" className="mt-4">
              <h2 className="m-0 text-white">Erro</h2>
              <div dangerouslySetInnerHTML={{ __html: errorMessages }} />
            </Alert>
          )}
        </>
      ) : (
        <div>
          <Card className="mt-4 shadow" color="success">
            <CardBody className="text-center text-white">
              <span>
                <i className="fas fa-check fa-3x" />
              </span>
              <h1>Sucesso</h1>
              <Card color="white" className="mt-3 ">
                <CardBody className="text-dark">
                  <p className="mb-0">Proposta:</p>
                  <h2 className="m-0" style={{ fontSize: '30px' }}>
                    {proposalData.numberProposal}
                  </h2>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
          <div>
            <Row>
              <Col md="6">
                <Button
                  color="primary"
                  size="lg"
                  className="w-100 mt-3"
                  onClick={() => {
                    history.push('/admin/inss/proposal/report');
                  }}
                >
                  ESTEIRA DE PROPOSTAS
                </Button>
              </Col>
              <Col md="6">
                <Button
                  color="success"
                  size="lg"
                  className="w-100 mt-3"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  NOVA PROPOSTA
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectedSimulationView;
