import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { HeaderTitle, BodyTitle } from 'views/Profile/styles';

import InputComponent from 'components/Form/Input/InputComponent';

export const AccountForm = ({
  profile = {},
  setProfile = () => {},
  handleSubmit = () => {},
  isLoading = false,
}) => {
  if (!profile.isPromoter || profile.isPromoter <= 0) {
    return <></>;
  }

  return (
    <Card>
      <CardHeader>
        <HeaderTitle>Minha Conta</HeaderTitle>
        {/* <LinkToSimulation
          href={urlSimulator}
          target="_blank"
          className="btn btn-primary"
        >
          <i className="fas fa-external-link-alt" /> Ver simulador
        </LinkToSimulation> */}
      </CardHeader>
      <CardBody>
        <BodyTitle>Informações da Conta</BodyTitle>
        <form>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <InputComponent
                    label="Nome"
                    type="text"
                    value={profile.name}
                    disabled
                  />
                </div>

                <div className="col-12 col-lg-6">
                  <InputComponent
                    label="E-mail"
                    type="text"
                    value={profile.email}
                    disabled
                  />
                </div>

                <div className="col-12 col-lg-6">
                  <InputComponent
                    label="Razão social"
                    type="text"
                    value={profile.corporativeName}
                    disabled
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <InputComponent
                    label="CNPJ"
                    type="text"
                    value={profile.document}
                    disabled
                  />
                </div>
              </div>
              <hr />

              <div className="row">
                <div className="col-12 col-lg-3">
                  <InputComponent
                    label="Nome fantasia"
                    type="text"
                    value={profile.fantasyName}
                    onChange={(e) => {
                      setProfile({
                        ...profile,
                        fantasyName: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-12 col-lg-3">
                  <InputComponent
                    label="E-mail de suporte"
                    type="text"
                    value={profile.contactEmail}
                    onChange={(e) => {
                      setProfile({
                        ...profile,
                        contactEmail: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-12 col-lg-3">
                  <InputComponent
                    label="E-mail envio fatura"
                    type="text"
                    value={profile.paymentEmail}
                    onChange={(e) => {
                      setProfile({
                        ...profile,
                        paymentEmail: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="col-12 col-lg-3">
                  <InputComponent
                    label="Telefone WhatsApp de suporte"
                    type="text"
                    value={profile.contactPhone}
                    onChange={(e) => {
                      setProfile({
                        ...profile,
                        contactPhone: e.target.value,
                      });
                    }}
                  />
                </div>
                {/* 
                <div className="col-12 col-lg-6">
                  <PreferenceBank
                    preferenceBank={preferenceBank}
                    handleChange={handleChange}
                  />
                </div>

                <div className="col-12 col-lg-6">
                  <Input
                    label="Nome simulador"
                    placeholder="Nome apresentado na url após o '/'"
                    name="nameSlug"
                    value={nameSlug}
                    onChange={handleChange}
                    onBlur={handleChange}
                    small={
                      <>
                        <strong>ATENÇÃO:</strong> Alterando este nome irá mudar
                        o QRCode e a URL.
                      </>
                    }
                    smallTheme="danger"
                  />
                </div> */}
              </div>
            </div>
            {/* <div className="col-12 col-lg-4 d-flex align-items-center">
              {urlSimulator ? (
                <QRWrapper>
                  <h3 className="d-lg-none">QRCode</h3>
                  <QRCode value={urlSimulator} size={200} />
                  <Input
                    label="URL"
                    placeholder="URL"
                    name="QrCodeURL"
                    value={urlSimulator}
                    clipboard={urlSimulator}
                    readOnly
                  />
                </QRWrapper>
              ) : (
                <QRWrapper>
                  <BeatLoader color="#5e72e4" size={20} />
                </QRWrapper>
              )}
            </div> */}
          </div>
        </form>
      </CardBody>
      <CardFooter>
        <Button
          onClick={handleSubmit}
          className="btn btn-success"
          disabled={isLoading}
        >
          <i className="fas fa-save" /> Salvar alterações{' '}
          {isLoading && <i className="fas fa-spin fa-circle-notch" />}
        </Button>
      </CardFooter>
    </Card>
  );
};
