import InputComponent from 'components/Form/Input/InputComponent';

import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { phoneMask } from 'utils/mask';
import { HeaderTitle, BodyTitle } from 'views/Profile/styles';

export const UserAccountFormComponent = ({
  profile = {},
  setProfile = () => {},
  handleSubmit = () => {},
  isLoading = false,
}) => {
  if (!profile?.isUser || profile?.isUser <= 0) {
    return <></>;
  }

  return (
    <Card>
      <CardHeader>
        <HeaderTitle>Minha Conta</HeaderTitle>
      </CardHeader>
      <CardBody>
        <BodyTitle>Informações da Conta</BodyTitle>
        <div className="row">
          <div className="col-12 col-lg-6">
            <InputComponent
              label="Nome"
              type="text"
              value={profile.name}
              disabled
            />
          </div>
          {profile?.isSeller > 0 && (
            <div className="col-12 col-lg-6">
              <InputComponent
                label="Nome empresa"
                type="text"
                value={profile.corporativeName}
                onChange={(e) => {
                  setProfile({
                    ...profile,
                    corporativeName: e.target.value,
                  });
                }}
              />
            </div>
          )}

          <div className="col-12 col-lg-6">
            <InputComponent
              label="email"
              type="text"
              value={profile.email}
              disabled
            />
          </div>

          {profile?.isSeller > 0 && (
            <div className="col-12 col-lg-6">
              <InputComponent
                label="Telefone Contato"
                type="text"
                value={
                  profile?.contactPhone
                    ? phoneMask(profile?.contactPhone)
                    : profile?.contactPhone
                }
                placeholder="(00) 0 0000-0000"
                onChange={(e) => {
                  setProfile({
                    ...profile,
                    contactPhone: phoneMask(e.target.value),
                  });
                }}
              />
            </div>
          )}
        </div>
      </CardBody>
      <CardFooter>
        <Button
          onClick={handleSubmit}
          className="btn btn-success"
          disabled={isLoading}
        >
          <i className="fas fa-save" /> Salvar alterações{' '}
          {isLoading && <i className="fas fa-spin fa-circle-notch" />}
        </Button>
      </CardFooter>
    </Card>
  );
};
