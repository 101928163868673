import api from 'services/api';

export const showProfileService = () => {
  return api.get(`v3/profile`);
};

export const updateProfileService = (
  data = {
    corporativeName: null,
    contactPhone: null,
  }
) => {
  return api.put(`v3/profile`, data);
};

export const showStyleUserService = () => {
  return api.get(`v3/style/user`);
}

export const saveStyleUserService = (data = {
  config: null,
  logo: null
}) => {
  return api.post(`v3/style/user`, data);
}
