import api from 'services/api';

export const listBankFgtsService = (data = { allBanks: false }) => {
  return api.get(`v3/bank/fgts`, {
    params: data,
  });
};

export const listAcceptPaymentService = (
  data = { bankId: null, febrabanId: null }
) => {
  return api.get(`v3/bank/accept-payment`, {
    params: data,
  });
};
