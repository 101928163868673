export const BanksSimulatorFGTS = [
  {
    id: 6,
    febrabanId: '329',
    name: 'ICRED',
  },
  {
    id: 19,
    febrabanId: '329-5',
    name: 'GRANDINO BANK',
  },
  {
    id: 3,
    febrabanId: '149',
    name: 'FACTA',
  },
  {
    id: 21,
    febrabanId: '531',
    name: 'PRESENÇA BANK',
  },
  {
    id: 20,
    febrabanId: '329-6',
    name: 'VCTEX',
  },
  {
    id: 4,
    febrabanId: '623',
    name: 'BANCO PAN',
  },
  {
    id: 22,
    febrabanId: '329-4',
    name: 'V8 DIGITAL',
  },
  {
    id: 17,
    febrabanId: '324',
    name: 'LOTUS MAIS',
  },
  {
    id: 14,
    febrabanId: '329-3',
    name: 'PRATA DIGITAL',
  },
  {
    id: 16,
    febrabanId: '274',
    name: 'NOVO SAQUE',
  },
];
