import { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { listBankFgtsService } from 'services/Register/BankService';

const InputFebrabanView = ({
  filter,
  setFilter,
  defaultValue = null,
  isClearInput = false,
  isMultSelect = false,
  nameLabel = 'Banco(s)',
}) => {
  const [banks, setBanks] = useState([]);

  const handleList = async () => {
    await listBankFgtsService()
      .then((result) => {
        if (!result?.data?.resultSet) {
          return;
        }

        const resultSet = result?.data?.resultSet.map((res) => {
          return {
            value: res.febraban,
            label: `${res.febraban} | ${res.name}`,
          };
        });

        resultSet.push({
          value: '999',
          label: '999 | NÃO IDENTIFICADO',
        });

        setBanks(resultSet);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <FormGroup>
      <Label>{nameLabel}</Label>
      {banks.length > 0 && (
        <Select
          className="basic-single"
          classNamePrefix="select"
          value={defaultValue}
          options={banks}
          isLoading={banks.length <= 0}
          placeholder={`Selecione...`}
          isClearable={isClearInput}
          isMulti={isMultSelect}
          onChange={(res) => {
            if (isMultSelect) {
              setFilter(res);
              return;
            }

            setFilter({
              ...filter,
              febraban: res?.value ?? null,
              nameBank: res?.label ?? null,
            });
          }}
        />
      )}

      {banks.length <= 0 && <p>Carregando...</p>}
    </FormGroup>
  );
};

export default InputFebrabanView;
