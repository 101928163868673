import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';
import Swal from 'sweetalert2';

const CardAfterHeaderComponent = ({ links, setLinks = () => {} }) => {
  const handleAdd = () => {
    setLinks([
      ...links,
      {
        title: 'Título',
        link: 'https://www.seulink.com.br',
        target: '_blank',
        btnColor: '#ffffff',
        textColor: '#333333',
      },
    ]);
  };

  const handleRemove = (key) => {
    if (links.length <= 1) {
      Swal.fire({
        icon: 'warning',
        title: 'Ops...',
        text: 'Você não pode remover todos os links!',
      });
      return;
    }

    setLinks(
      links.filter((link, keyLink) => {
        return key !== keyLink;
      })
    );
  };

  return (
    <>
      {links.length > 0 && (
        <Card className="shadow mt-3">
          <CardHeader>
            <Row>
              <Col>
                <h3 className="m-0">
                  <i className="fas fa-link" /> Cards
                </h3>
              </Col>
              <Col className="text-right">
                <Button color="primary" onClick={handleAdd}>
                  <i className="fas fa-plus" /> Adicionar Novo Card
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {links.map((link, key) => (
              <Card key={key} className="shadow mb-3">
                <CardBody>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Título do Card</Label>
                        <InputGroup>
                          <Input
                            className="font-weight-bold font-20"
                            placeholder="Título"
                            value={link.title}
                            maxLength="60"
                            onChange={(ev) => {
                              const newLinks = [...links];
                              links[key].title = ev.target.value;
                              setLinks(newLinks);
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Descrição do Card</Label>
                        <InputGroup>
                          <Input
                            className="font-weight-bold font-20"
                            placeholder="Descrição do Link"
                            value={link.description}
                            maxLength="60"
                            onChange={(ev) => {
                              const newLinks = [...links];
                              links[key].description = ev.target.value;
                              setLinks(newLinks);
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label>URL da página</Label>
                    <InputGroup size="sm">
                      <Input
                        className="font-12"
                        placeholder="URL - Página"
                        value={link.link}
                        onChange={(ev) => {
                          const newLinks = [...links];
                          links[key].link = ev.target.value;
                          setLinks(newLinks);
                        }}
                        maxLength="180"
                      />
                      <select
                        className="form-control form-control-md col-md-4 border"
                        value={link.target}
                        onChange={(ev) => {
                          const newLinks = [...links];
                          links[key].target = ev.target.value;
                          setLinks(newLinks);
                        }}
                      >
                        <option value="_self">Abrir link na mesma aba</option>
                        <option value="_blank" selected>
                          Abrir link em nova aba
                        </option>
                      </select>
                    </InputGroup>
                  </FormGroup>
                  {links.length > 1 && (
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleRemove(key)}
                    >
                      <i className="fas fa-trash-alt" /> Remover
                    </Button>
                  )}
                </CardBody>
              </Card>
            ))}
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default CardAfterHeaderComponent;
